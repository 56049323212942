import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const ContentPage = () => (
  /* ...GatsbyImageSharpFluid */
  <StaticQuery
    query={graphql`
      {
        file(
          relativePath: {
            regex: "/archimede_energia_e_servizi/content/ilsole/"
          }
        ) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Container>
          <div className="p-5">
            <Row className="mt-5 no-gutters">
              <Col>
                <h3 className="text-left">Presentazione</h3>
                <hr />
                <div
                  className="image-wrapper float-right pt-4 pl-4 pb-4"
                  style={{
                    width: `30rem`,
                  }}
                >
                  <Img
                    fluid={data.file.childImageSharp.fluid}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="p-3">
                  Archimede Energia & Servizi Srl è una Energy Service Company
                  (E.S.Co) che offre servizi energetici integrati finalizzati al
                  raggiungimento dell’efficienza energetica. In quanto tale, è
                  in grado di commercializzare prodotti energetici, energia
                  elettrica ed energia in genere, e di avviare la compravendita
                  di titoli energetici quali: certificati verdi, certificati
                  bianchi, Recs ed affini. Archimede Energia & Servizi Srl opera
                  nel rispetto di quanto disposto dal D.P.R. 26 agosto 1993, n.
                  412 e dal D.Lgs 115/08, e svolge la propria attività in
                  conformità alla norma tecnica UNI CEI 11352:2010 “Società che
                  forniscono servizi energetici E.S.Co.”. Archimede Energia &
                  Servizi Srl è un operatore di mercato abilitato sia nei
                  Mercati Ambientali, quindi nel mercato dei Certificati Verdi e
                  dei Titoli di Efficienza Energetica, che nella Piattaforma
                  Conti Energia (PCE) per le transazioni bilaterali di energia
                  elettrica.Grazie alla sinergia con le altre aree presenti
                  all’interno della propria struttura e ai propri partner,
                  Archimede Energia & Servizi Srl garantisce l’accesso ad un
                  mondo di servizi, in grado di soddisfare appieno ogni esigenza
                  dei propri clienti, dalla strutturazione dell’investimento,
                  all’accesso al credito, dalla ingegnerizzazione all’appalto,
                  dalla realizzazione alla completa gestione. Ciò permette al
                  cliente di dedicarsi al suo core business ricorrendo al out
                  sourcing per i servizi estranei che possono essere affidati
                  alla nostra società, la quale dispone di un know-how
                  pluriennale nel settore della green energy e nella gestione
                  degli impianti. Archimede Energia & Servizi Srl, ad oggi,
                  gestisce ben 20 MWp di impianti FV situati in Sicilia e in Sud
                  Italia. L’efficienza del nosto lavoro è assicurata dal
                  software di simulazione “Elios”, completamente implementato
                  “in house”. Elios è un sistema di supervisione, monitoraggio
                  ed acquisizione dati (SCADA) che permette di tenere sotto
                  controllo ogni tipologia di impianto fotovoltaico poiché è in
                  grado di interfacciarsi con tutti inverters che dispongono di
                  porta di comunicazione RS485, standard per i prodotti delle
                  maggiori case produttrici. Con un semplice browser internet
                  (p.e. Internet Explorer), e senza dover installare nessun
                  software, si accede, mediante apposite credenziali di accesso
                  personali, ad un portale web dedicato all’impianto. Dal sito è
                  possibile monitorare in tempo reale l’impianto, verificare le
                  prestazioni ed i rendimenti, stampare i report, consultare i
                  dati storici e svolgere altre attività avanzate, attivabili su
                  richiesta (gestione delle manutenzioni programmate, gestione
                  dell’officina elettrica, gestione amministrativa, etc.).In
                  sistema permette, inoltre, di creare degli accessi multipli
                  che consentono l’utilizzo, oltre che al cliente, anche
                  all’installatore incaricato del servizio di manutenzione.
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    )}
  />
)

export default ContentPage
