import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Container, Col, Row, Image } from "react-bootstrap"

import HeaderStyles from "./header1.module.css"

/* import Img from "gatsby-image" */

import BackgroundImage from "gatsby-background-image"

const Header1 = ({ src_sfondo, data_img, className, title }) => {
  return (    
      <BackgroundImage
        id={HeaderStyles.HeaderBackground}
        Tag="section"
        className={className}
        fluid={src_sfondo}
        backgroundColor={`#040e18`}
      >
        <div
          id={HeaderStyles.HeaderColorBackground}
          className="h-100 align-item-center"
        >
          <Container fluid className="vh-100">
            <Row className="h-100 align-items-center">
              <Col></Col>
              <Col
                xs={9}
                sm={6}
                md={5}
                lg={4}
                xl={3}
                id={HeaderStyles.DivHeader}
                className="text-center"
                style={{
                  backgroundColor: `white`
                }}
              >
                <Link id={HeaderStyles.LinkTitleHeader} to="/">
                <Image src={data_img.src} fluid alt=""/>
                  {/* <Img
                    fixed={data_img}
                    alt=""
                  /> */}
                </Link>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </div>
      </BackgroundImage>

  )
}

Header1.propTypes = {
  title: PropTypes.string,
  src_sfondo: PropTypes.object,
  data_img: PropTypes.object,
}

Header1.defaultProps = {
  title: ``,
  src_sfondo: {},
  data_img: {},
}

export default Header1
