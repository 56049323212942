import React from "react"
import { Link } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"

const Bar = position => (
  <Container>
    <div className="py-5">
      <Row>
        <Col md={1} lg={3} className="d-block d-sm-block d-md-none d-lg-block"></Col>
        <Col md={4} lg={3} className="my-2">
          <Link className="btn btn-primary mx-3" to="/gruppo">
            Torna al gruppo
          </Link>
        </Col>
        <Col md={4} lg={3} className="my-2">
          <Link
            to="/gruppo"
            className="btn btn-primary mx-3"
            state={{
              is_scroll: true,
              path: "#societa_controllate",
              time: 1000,
            }}
          >
            Torna a società controllate
          </Link>
        </Col>
        <Col md={1} lg={3} className="d-block d-sm-block d-md-none d-lg-block"></Col>

      </Row>
    </div>
  </Container>
)

export default Bar
