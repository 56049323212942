import React from "react"
import { Container } from "react-bootstrap"

import Content1 from "./content1"
import Content2 from "./content2"

const IndexContentPage = () => (
  <>
    <Container>
      <Content1 />
      <Content2 />
    </Container>
  </>
)

export default IndexContentPage
