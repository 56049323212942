import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const ContentPage = () => (
  /* ...GatsbyImageSharpFluid */
  <StaticQuery
    query={graphql`
      {
        file(
          relativePath: {
            regex: "/archimede_energia_e_servizi/content/fotovoltaico/"
          }
        ) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Container>
          <div className="p-5">
            <Row>
              <Col>
                <h3 className="text-right">Attività</h3>
                <hr />
              </Col>
            </Row>

            <Row className="mt-5 no-gutters">
              <Col>
                <div
                  className="image-wrapper float-left pt-4 pr-4 pb-4"
                  style={{
                    width: `30rem`,
                  }}
                >
                  <Img
                    fluid={data.file.childImageSharp.fluid}
                    alt=""
                    loading="lazy"
                  />
                </div>
              </Col>

              <Col>
                <div className="p-3">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Gestione Tecnica</li>
                    <li className="list-group-item">
                      Consulenza amministrativa e fiscale
                    </li>
                    <li className="list-group-item">Energy Trading</li>
                    <li className="list-group-item">Monitoraggio</li>
                    <li className="list-group-item">Reportistica</li>
                    <li className="list-group-item">
                      Pianificazione della manutenzione
                    </li>
                    <li className="list-group-item">Pronto intervento</li>
                    <li className="list-group-item">
                      PR e disponibilità garantiti
                    </li>
                    <li className="list-group-item">Pulizia moduli</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    )}
  />
)

export default ContentPage
